import React from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  IconButton,
  Card,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import SendIcon from "@mui/icons-material/Send";
import { colors } from "../lib/theme";

const ShowGeneratedQuestions = ({
  generatedQuestions,
  additionalDetails,
  setAdditionalDetails,
  handlePrevious,
  handleQuestionChange,
  handleRemoveQuestion,
  newQuestion,
  setNewQuestion,
  handleAddQuestion,
  updateQuestions,
  handleShowPreview, // Add this prop
}) => (
  <>
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <Card
        sx={{
          p: 2,
          mb: 4,
          background: colors.navy,
          border: `1px solid ${colors.lightGray}`,
        }}
      >
        <Typography variant="body1" sx={{ color: colors.white }}>
          <strong>Optional:</strong> Want to add a few personal details?
        </Typography>
        <Typography variant="body2" gutterBottom sx={{ color: colors.white }}>
          Let us know if there's anything specific you'd like to know or ask
          them and we'll refine the questions below.
        </Typography>
        <TextField
          fullWidth
          multiline
          rows={3}
          value={additionalDetails}
          onChange={(e) => setAdditionalDetails(e.target.value)}
          variant="outlined"
          placeholder="Type any additional details here"
          sx={{ width: "100%", maxWidth: "100%" }}
        />
        <Button
          variant="contained"
          size="small"
          color="primary"
          onClick={updateQuestions}
          sx={{ mt: 2, p: 1.5, fontSize: "0.75rem" }}
        >
          Update questions
        </Button>
      </Card>
      {generatedQuestions.map((q, index) => (
        <Box key={q.id} sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <TextField
            fullWidth
            multiline
            rows={3}
            value={q.text}
            onChange={(e) => handleQuestionChange(q.id, e.target.value)}
            variant="outlined"
            size="small"
            sx={{ width: "100%", maxWidth: "100%" }}
          />
          <IconButton onClick={() => handleRemoveQuestion(q.id)} color="error">
            <DeleteIcon data-testid="DeleteIcon" />
          </IconButton>
        </Box>
      ))}
      <Box sx={{ display: "flex", alignItems: "center", gap: 1, mt: 2 }}>
        <TextField
          fullWidth
          multiline
          rows={3}
          value={newQuestion}
          onChange={(e) => setNewQuestion(e.target.value)}
          variant="outlined"
          size="small"
          placeholder="Type a new question here"
          sx={{ width: "100%", maxWidth: "100%" }}
        />
        <IconButton onClick={handleAddQuestion} color="primary">
          <AddIcon data-testid="AddIcon" />
        </IconButton>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
        <Button
          onClick={handlePrevious}
          variant="text"
          sx={{ mt: 2, border: 0, color: colors.lightGray }}
        >
          Previous
        </Button>
        <Button
          variant="secondary"
          startIcon={<SendIcon />}
          sx={{ mt: 2 }}
          onClick={handleShowPreview} // Add this onClick handler
        >
          Gift this
        </Button>
      </Box>
    </Box>
  </>
);

export default ShowGeneratedQuestions;
